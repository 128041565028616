<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Blend Ratio
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fa-solid fa-mug-saucer</v-icon
                      >
                    </p> -->
                  </div>

                  <v-dialog v-model="dialog" max-width="850px" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" elevation="0" dark :ripple="false" height="43"
                        class=" font-weight-bold text-capitalize ms-auto btn-primary bg-success py-3 px-6 ms-3 ">New
                        Ratio</v-btn>
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>

                      <v-card-text class="card-padding">
                        <v-container class="px-0">
                          <v-form ref="frm">
                            <v-row>
                              <v-col cols="12" md="4">
                                <label class=" text-md text-typo font-weight-bolder ms-1 ">Blend Type</label>
                                <v-autocomplete v-model="editedItem.blend_type_id" :items="blendType" item-text="name"
                                  item-value="id" color="rgba(0,0,0,.6)"
                                  class=" input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-5 mb-0 "
                                  placeholder="Select a Blend type" :rules="[
                                    (v) => !!v || 'Blend type is Required',
                                  ]" outlined single-line height="48">
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="4">
                                <label class=" text-md text-typo font-weight-bolder ms-1 ">Tea Type</label>
                                <v-autocomplete v-model="editedItem.tea_type_id" :items="teaType"
                                  item-text="tea_type_name" item-value="id" color="rgba(0,0,0,.6)"
                                  class=" input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-5 mb-0 "
                                  placeholder="Select a tea type" :rules="[
                                    (v) => !!v || 'Tea type is Required',
                                  ]" outlined single-line height="48">
                                </v-autocomplete>
                              </v-col>
                              <v-spacer></v-spacer>
                              <action-button :actions="currentPage.actions_allowed" @onClick="allowedAction"
                                v-if="editedItem.status === 'PENDING' || editedItem.status === 'APPROVE'" />
                            </v-row>
                            <v-row justify="center" v-for="(row, i) in editedItem.blend_ratio_details.length"
                              v-bind:key="i">
                              <v-col cols="4">
                                <label class=" text-md text-typo font-weight-bolder ms-1 ">Item
                                  Name</label>
                                <v-text-field v-model="
                                  editedItem.blend_ratio_details[i].item_name
                                " hide-details="auto" @keyup="uppercase(i)"
                                  class=" input-style font-size-input text-light-input placeholder-light input-icon text-capitalize "
                                  dense flat filled solo placeholder="Item" :rules="[
                                    (v) => !!v || 'Item name is required',
                                  ]"></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <label class=" text-md text-typo font-weight-bolder ms-1 ">Percentage</label>
                                <v-text-field v-model.number="
                                  editedItem.blend_ratio_details[i].percentage
                                " type="number" min="1" max="100" hide-details="auto"
                                  class=" input-style font-size-input text-light-input placeholder-light input-icon "
                                  @change="validatePercentageSum(i)" dense flat filled solo placeholder="Percentage"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Valid percentage is required',
                                  ]"></v-text-field>
                              </v-col>
                              <v-col cols="2" class="pa-1" align-self="center">
                                <span class="pa-2" align-self="center">
                                  <v-btn class="mx-2 mt-3 input-style" outlined small color="red" @click="remove(i)"
                                    v-show="
                                      i ||
                                      (!i &&
                                        editedItem.blend_ratio_details.length >
                                        1)
                                    ">Remove
                                  </v-btn>
                                </span>
                              </v-col>
                              <v-col cols="2" class="pa-1" align-self="center">
                                <span class="pa-2" align-self="center">
                                  <v-btn class="mx-2 mt-3" outlined small color="green" @click="add()" v-show="
                                    i ==
                                    editedItem.blend_ratio_details.length - 1
                                  ">Add
                                  </v-btn>
                                </span>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn @click="close" elevation="0" :ripple="false" height="43"
                          class=" font-weight-bold text-capitalize btn-ls btn-secondary bg-light py-3 px-6 ">Close</v-btn>
                        <v-btn @click="save" elevation="0" :ripple="false" height="43" dark
                          class=" text-capitalize btn-ls btn-primary bg-success py-3 px-6 ">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="items" :search="search" class="table" :page.sync="page"
                  hide-default-footer @page-count="pageCount = $event" :items-per-page="itemsPerPage"
                  mobile-breakpoint="0">
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field hide-details
                            class=" input-style font-size-input text-light-input placeholder-light input-icon " dense flat
                            filled solo height="43" v-model="search" placeholder="Search">
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card class="card-shadow card-padding border-radius-xl">
                          <v-card-title class="pt-0 text-h5 text-typo justify-center">Are you sure you want to delete this
                            item?</v-card-title>
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn @click="closeDelete" elevation="0" :ripple="false" height="43"
                              class=" font-weight-bold text-capitalize btn-ls bg-light py-3 px-6 ">Cancel</v-btn>
                            <v-btn @click="deleteItemConfirm" elevation="0" :ripple="false" height="43"
                              class=" font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 ">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <!-- [`item.actions`]="{ item }" -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <span> Example test {{ item.district }}</span> -->
                    <v-btn @click="editItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36"
                      width="36" class="" color="green">Edit
                    </v-btn>

                    <v-btn @click="deleteItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36"
                      width="36" class="ml-5" color="#FF0000">
                      delete
                    </v-btn>
                  </template>
                  <template v-slot:[`item.active`]="{ item }">
                    <span v-if="item.active"> Active </span>
                    <span v-else> Inactive </span>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="3" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-text-field hide-details type="number" outlined min="-1" max="15"
                      background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage"
                      @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page"
                      class=" font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon ">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination"
                      color="#38bd34" v-model="page" :length="pageCount" circle></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import api from "../api";
import { mapGetters } from "vuex";
import ActionButton from "../../../../components/ActionButton.vue";

export default {
  name: "paginated-tables",
  components: { ActionButton },

  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      mnu_frm_date: false,
      mnu_to_date: false,
      blendType: [],
      teaType: [],
      items: [],
      tempItems: [],
      search: "",
      to_validation: "",
      editedIndex: -1,
      editedItem: {
        id: 0,
        //name: "",
        blend_type_id: 0,
        tea_type_id: 0,
        blend_ratio_details: [{ item_name: "", percentage: "" }],
      },
      defaultItem: {
        id: 0,
        blend_type_id: 0,
        tea_type_id: 0,
        blend_ratio_details: [{ item_name: "", percentage: "" }],
      },
      headers: [
        // {
        //   text: "Blend Code",
        //   width: 250,
        //   sortable: true,
        //   value: "blendcodename",
        // },
        {
          text: "Blend Type",
          width: 250,
          sortable: true,
          value: "blendtypename",
        },
        {
          text: "Active status",
          width: 250,
          sortable: true,
          value: "active",
        },
        {
          text: "Approval status",
          width: 250,
          sortable: true,
          value: "status",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {

    async initialize() {
      this.tempItems = [];
      this.items = [];
      let res = await api.getBlendRatio();
      console.log(res);
      this.items = res;

      this.tempItems = structuredClone(this.items);
      this.blendType = await api.getUnassignedBlendNames();
      this.teaType = await api.getTeaTypes();
    },

    reset() {
      return this.$refs.frm.reset();
    },

    //**Validate**//

    validate() {
      return this.$refs.frm.validate();
    },

    async validatePercentageSum(i) {
      let sum = 0;
      await this.editedItem.blend_ratio_details.forEach((item, j) => {
        if (j <= i && item.percentage) {
          sum += item.percentage;
        }
      });

      if (sum > 100) {
        this.editedItem.blend_ratio_details[i].percentage = 0;
        this.showWarningAlert("Combined percentage should be 100");
      }
    },

    async validatePercentage() {
      let sum = 0;

      for await (const item of this.editedItem.blend_ratio_details) {
        if (item.percentage) {
          sum += item.percentage;
        }
      }

      if (sum != 100) {
        this.showWarningAlert("Combined percentage should be 100");
      }
      return sum;
    },

    //**Delete**//

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.deleteBlendRatio(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);
        await this.initialize();
        this.showSuccessAlert(`Ratio Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    async closeDelete() {
      this.dialogDelete = false;
      // this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialog = false;
      this.blendType = await api.getUnassignedBlendNames();
    },

    //**Edit Item**//

    async editItem(item) {
      this.blendType = [{
        id: item.blendtypeid,
        name: item.blendtypename
      }];
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.blend_type_id = this.blendType[0];

      this.dialog = true;
      console.log('item', item);
      console.log('editedItem', this.editedItem);
    },

    async allowedAction(item) {
      this.overlay = true;
      try {
        let resp = await api.allowedActionBlendRatio({
          id: this.editedItem.id,
          action: item,
        });

        this.overlay = false;
        this.items = await api.getBlendRatio();
        this.tempItems = structuredClone(this.items);
        this.editedItem.status = resp.action;

        this.showSuccessAlert("State updated");
      } catch (error) {
        this.showErrorAlert(error);
      }
    },

    add() {
      this.editedItem.blend_ratio_details.push({
        item_name: "",
        percentage: "",
      });
    },

    remove(index) {
      this.editedItem.blend_ratio_details.splice(index, 1);
    },

    async save() {
      if (this.validate()) {
        const per_val = await this.validatePercentage();
        if (per_val === 100) {
          try {
            let type = "Saved";
            let item = null;
            let resp = null;
            //console.log('item', this.editedItem);
            if (this.editedIndex > -1) {
              type = "Updated";
              resp = await api.updateBlendRatio(this.editedItem.id, this.editedItem);
              item = resp.data;
              Object.assign(this.items[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.name = `Ratio-` + this.editedItem.blend_type_id;
              this.editedItem.active = true;
              this.editedItem.status = 'PENDING';
              resp = await api.createBlendRatio(this.editedItem);
              item = resp.data;
            }
            //console.log('item', item);
            if (resp.statusCode === 200) {
              await this.initialize();
              this.showSuccessAlert(`Ratio ${type}.`);
            } else {
              this.showErrorAlert(item.message);
            }
          } catch (err) {
            this.showErrorAlert(err.message);
          } finally {
            this.close();
          }
        }
      }
    },

    async close() {
      this.dialog = false;
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.items = structuredClone(this.tempItems);
      this.blendType = await api.getUnassignedBlendNames();
    },

    //****//

    uppercase(i) {
      this.editedItem.blend_ratio_details[i].item_name =
        this.editedItem.blend_ratio_details[i].item_name.toUpperCase();
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
    },

    showWarningAlert(message) {
      this.$swal({
        title: "Wrong input",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Ratio   " : "Edit Ratio";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },

};
</script>
