import axios from "axios";

export default {

  async createBlendRatio(item) {
    return await axios
      .post(`blend/ratio/`, item)
      .then((res) => {
        console.log("RES", res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

  async getBlendRatio() {
    return await axios
      .get(`blend/ratio`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  deleteBlendRatio(id) {
    return axios
      .delete(`blend/ratio/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  updateBlendRatio(id, item) {
    return axios
      .patch(`blend/ratio/` + id, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getUnassignedBlendNames() {
    return await axios
      .get(`blend/blend-names-unassigned`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getBlendNames() {
    return await axios
      .get(`blend/blend-names`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getTeaTypes() {
    return await axios
      .get(`tea-type`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getRatioStock(id) {
    return await axios
      .get(`blend/ratio-with-stock/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async allowedActionBlendRatio(req) {
    return await axios
      .post("blend/ratio-action/", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getRequestByRatio(ratioId) {
    return await axios
      .get(`purchase-request/` + ratioId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getRequests() {
    return await axios
      .get(`purchase-request/all`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getRequestDetails(id) {
    return await axios
      .get(`purchase-request/details/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async downloadPurchaseRequest(requestIds) {
    let date_time = new Date();
    date_time = `${(date_time.getMonth() + 1).toString()
      .padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time.getFullYear().toString()}`;
    let fileName = `Purchase-Advice-` + date_time.replaceAll(`/`, `-`);

    return await axios
      .post(`purchase-request/download-purchase-advice/`, requestIds,
        {
          responseType: "blob",
        })
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + `.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async createPurchaseRequest(purchaseRequest) {
    return await axios
      .post(`purchase-request/`, purchaseRequest)
      .then((res) => {
        console.log("RES", res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

  async allowedActionPurchaseRequest(req) {
    axios
      .post("purchase-request/action/", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

};
